<template>
  <base-section
    id="conveyorlubricant"
    space="36"
  >

      <div v-if="$route.name=='컨베이어 윤활제'">
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>
      <base-section-heading title="컨베이어 윤활제란?" />
      <v-container
      class="text-center"
      >
      컨베이어 윤활제는 식음료, 주류 및 GMP(Good Manufacturing Practices)설비의 용기 이송을 위한 전용 윤활제 입니다.<br>
      윤확제 선택시 다양한 용기에 적합한 원활한 이송 능력, 세척 및 살균력, 친환경성 등과 같은 만은 요인들이 고려 되어야 합니다.<br>
      <br>
      남강의 윤활제는 다음과 같은 특성이 있습니다.<br>
      윤활성이 매우 좋습니다.<br>
      기포발생이 적고, 세척력이 우수하여 청결한 작업환경을 유지 할 수 있습니다.<br>
      이송 용기에 Streess Creaking, 부식 및 노화를 주지 않습니다.<br>
      사용시 냄새가 없습니다.<br>
      생분해도가 높고 소포도가 우수하여 폐수처리가 용이 합니다.<br>
      희석배수가 높아 경제성이 우수합니다.<br>
      당사가 제공하는 자동공급장치를 사용하시면 적정농도를 자동으로 연속 공급할 수 있습니다.
      <br>
      </v-container>
      <v-container>
      </v-container>

      <v-container>
      </v-container>
      <base-section-heading title="제품군" />
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                지방산계 다목적 체인윤활제
              </v-subheader>
            </v-card>
          </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                내압페트전용 체인윤활제
              </v-subheader>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                아민계 체인윤활제
              </v-subheader>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                GMP 설비용 체인윤활제
              </v-subheader>
            </v-card>
        </v-col>
  </base-section>
</template>

<script>
  export default {
    name: 'Sectionconveyorlubricant',
    data: () => ({
      inset: false,
      drawer: null,
      items: [
        'Cip',
        'Cop',
        '세병첨가제',
        '컨베이어 윤활제',
        '생수공장용',
        '수질용',
      ],
    }),

    provide: {
      theme: { isDark: false },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },

  }
</script>
<style scoped>
.v-subheader {
    font-size: 18px;
    color: coral !important;
}
</style>
